import React from "react"
import { Link } from "gatsby"

import { IconContext } from "react-icons"
import { FaEnvelope, FaPhoneAlt, FaFacebookSquare, FaInstagram, FaTwitter } from "react-icons/fa"

import footerStyles from "../styles/components/footer.module.css"

const Footer = () => (
  <footer>
    <div className={ footerStyles.ourLocation }>
      <h5>Our Location</h5>
      <p><strong>Flair Talent Bureau</strong></p>
      <p>The IMC Block</p>
      <p>141 Scout Rallos Extension</p>
      <p>Sacred Heart, Quezon City, Metro Manila 1103 PH</p>
      <div className={ footerStyles.email }>
        <IconContext.Provider value={{ size: "1em", color: "#0F0E0E" }}>
          <FaEnvelope />
        </IconContext.Provider>
        <p>info@flair.com.ph</p>
      </div>
      <div className={ footerStyles.trunkLine }>
        <IconContext.Provider value={{ size: "1em", color: "#0F0E0E" }}>
          <FaPhoneAlt />
        </IconContext.Provider>
        <p>(02) 3448-5386</p>
      </div>
    </div>
    <div className={ footerStyles.footerNav }>
      <h5>Navigation</h5>
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about/">About</Link></li>
        <li><Link to="/services/">Services</Link></li>
        <li><Link to="/news/">News</Link></li>
        <li><Link to="/contact/">Contact Us</Link></li>
      </ul>
    </div>
    <div className={ footerStyles.socialMedia }>
      <h5>Social Media</h5>
      <ul>
        <li>
          <IconContext.Provider value={{ size: "1.25em", color: "#0F0E0E" }}>
            <FaFacebookSquare />
          </IconContext.Provider>
          <a href="https://www.facebook.com/flairbureau/">Facebook</a>
        </li>
        <li>
          <IconContext.Provider value={{ size: "1.25em", color: "#0F0E0E" }}>
            <FaTwitter />
          </IconContext.Provider>
          <a href="https://twitter.com/flairph?lang=en">Twitter</a>
        </li>
        <li>
          <IconContext.Provider value={{ size: "1.25em", color: "#0F0E0E" }}>
            <FaInstagram />
          </IconContext.Provider>
          <a href="https://www.instagram.com/flair_ph/">Instagram</a>
        </li>
      </ul>
    </div>
    <p className={ footerStyles.copyrightText }>
      Copyright © 2020, Flair Talent Bureau. All Rights Reserved.
    </p>
  </footer>
)

export default Footer